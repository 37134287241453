//useEffect = Método chamado ao carragar a página;
import React, { useEffect, useState, useContext} from 'react';
import { browserName, browserVersion } from "react-device-detect";
import { FaWhatsapp, FaScroll, FaPhotoVideo, FaDownload, FaTiktok, FaInstagram, FaTwitter, FaFacebook, FaYoutube, FaTelegram, FaTimes, FaBars } from "react-icons/fa";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from 'react-image-gallery';
import Modal from 'react-modal';
import imgBackground from '../../assets/images/background.jpg';
import './styles.css';
import '../../App.css';

Modal.setAppElement(document.getElementById('root'));

export default function Index({ history }) {
  const [loading, setLoading] = useState(true);
  const [toggleMenu, setToggleMenu] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const [modalShow, setModalShow] = useState(true);

  const customStylesModal = {
    overlay: {
      background: 'rgba(0,0,0,0.5)',
      zIndex: 999
    }, 
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '95%',
      maxWidth: '550px',
      border: 0,
      padding: '15px',
      borderRadius: '15px',
      boxShadow: '0px 0px 5px -2px #bbbbbb'
    }
  };

  /*useEffect(() => {
    const onScroll = e => {
      if(browserName === "Mobile Safari"){
        setScrollTop(e.target.body.scrollTop);
        //console.log(e.target.body.scrollTop)
      } else {
        setScrollTop(e.target.documentElement.scrollTop);
        //console.log(e.target.documentElement.scrollTop);
      }
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);*/
  
  return (
    <div id="homeIndex">
      {scrollTop < 150 ? <></> : <></>}
      {toggleMenu ?
        <div id="menu">
          <ul className="text-center">
            <li><a href="#bodyKnow" onClick={() => setToggleMenu(!toggleMenu)}>
              <span> Quem Sou </span>
            </a></li>
            <li><a href="#bodyPlans" onClick={() => setToggleMenu(!toggleMenu)}>
              <span> Materiais </span>
            </a></li>
            <li><a href="#" className="btn btn-default" style={{fontSize: 25}}>
              <span> Contribuir </span>
            </a></li>
            {/*<li><a href="/login">
              <span> Entrar </span>
            </a></li>
            <li><a href="#bodyPlans" className="btn btn-default" style={{fontSize: 25}}>
              <span> Criar Conta </span>
            </a></li>*/}
          </ul>
        </div>
      : <></> }
      <div id="navbar" className={scrollTop > 150 ? "onScroll" : ""}>
        {/*<a href="#bodyPlans" onClick={() => {}} className="text-center infoTop">
          <b>OFERTA</b> de <b>LANÇAMENTO</b> com <b>40% de DESCONTO</b> e <b>FRETE GRÁTIS</b> para a região <b>SUL</b> e <b>SUDESTE</b>!
          </a>*/}
        <div className="container">
          <div className="row v-center">
            <div className="col-6 col-sm-6 col-md-4 col-lg-4 logo">
              <h1 onClick={() => window.location = './'}>Seu Nome</h1>  
               {/*<a href="./">
               <img src={logo}/>
              </a>*/}
            </div>
            <div className="col-lg-8 text-right hidden-xs hidden-sm hidden-md" style={{marginTop: 5}}>
              <a href="#bodyKnow">
                <span> Quem Sou </span>
              </a>
              <a href="#bodyPlans">
                <span> Materiais </span>
              </a>
              <a href="#" className="btn btn-default" style={{marginTop: -5}}>
                <span> Contribuir </span>
              </a>
              {/*<a href="/login">
                <span> Entrar </span>
              </a>
             */}
            </div>
            <div className="col-6 col-sm-6 col-md-8 text-right visible-xs visible-sm visible-md">
              <button type="button" className="btn btn-default btnMenu" onClick={() => setToggleMenu(!toggleMenu)}>
                {toggleMenu ?
                  <FaTimes size="1.2em"/>
                : 
                  <FaBars size="1.2em"/>
                } 
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="bodyIndex v-center">
        <div className="container">
          <div className="row v-center">
            <div className="col-md-12 col-12">
              <h1>Aqui o LEMA da sua CAMPANHA!</h1>
              <p>Alguma breve descrição</p>
              <br /><br /><br /><br />
              <div className="row">
                <div className="col-12 divRedeSocial">
                  <a href="#" target="_Blank" className="btnRedeSocial">
                    <FaInstagram size="2em" />  
                  </a>
                  <a href="#" target="_Blank" className="btnRedeSocial">
                    <FaTwitter size="2em" /> 
                  </a>
                  <a href="#" target="_Blank" className="btnRedeSocial">
                    <FaFacebook size="2em" /> 
                  </a>
                  <a href="#" target="_Blank" className="btnRedeSocial">
                    <FaTelegram size="2em" /> 
                  </a>
                  <a href="#" target="_Blank" className="btnRedeSocial">
                    <FaWhatsapp size="2em" /> 
                  </a>
                  <a href="#" target="_Blank" className="btnRedeSocial">
                    <FaTiktok size="2em"/> 
                  </a>
                  <a href="#" className="btnRedeSocial" style={{marginRight: 0}}>
                    <FaYoutube size="2em" /> 
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*<div className="divider">
        <div className="container">
          <div className="row v-center">
            <div className="col-12 col-md-8 text-left">
              <h3>Escolha um kit e tenha em mãos uma experiência completa!</h3>  
            </div>
            <div className="col-12 col-md-4 text-center">
              <a href="#bodyPlans" className="btn btn-default-outline col-12" style={{marginTop: 10, marginBottom: 10}}>
                <span> Escolher Kit </span>
              </a>  
            </div>
          </div>
        </div>
      </div>*/}
      <div id="bodyKnow">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h1>Quem Sou?</h1>
              <span>Conheça quem sou e confira a minha trajetória até aqui!</span>  
              <br /><br /><br />
            </div>
          </div>
          <div className="row v-center" style={{marginBottom: 30}}>
            <div className="col-12 col-md-5 order-2 order-md-1">
              <div className="text-center">
                <h4 style={{marginTop: 15, marginBottom: 20}}>Esse é o meu Objetivo na política!</h4>
              </div>
              <p>Aqui um vídeo de sobre você e uma breve descrição dos seus ideais, objetivos e afins.</p>
              <p>Utilize esse espaço para colocar o que achar melhor. Esse texto deve instigar à assistir ao vídeo ao lado</p>
            </div>
            <div className="col-12 col-md-7 order-1 order-md-2 text-center">
              <div id="divVideo" className="embed-responsive embed-responsive-16by9">
                <iframe className="embed-responsive-item" width="100%" height="100%" src="https://www.youtube.com/embed/7maf1l3vB4E?rel=0&amp;showinfo=0" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              </div>
            </div>
          </div>
          <div className="row v-center" style={{marginBottom: 30}}>
            <div className="col-12 col-md-5 order-2 order-md-1 text-center">
              <img src={require('../../assets/images/foto-perfil.png')} width="100%" style={{maxWidth: 350}}/>
            </div>
            <div className="col-12 col-md-7 order-1 order-md-2">
              <div className="text-center">
                <h4 style={{marginTop: 15, marginBottom: 20}}>BIOGRAFIA</h4>
              </div>
              <p>Aqui a sua biografia...</p>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis hendrerit, ex quis faucibus tempus, odio turpis rhoncus felis, ac aliquet arcu quam non dui. Mauris laoreet orci vel est tristique, et condimentum risus aliquet. Aliquam at lectus sed tortor luctus faucibus nec a neque. Maecenas ex lectus, porttitor tristique porttitor non, iaculis sed lectus.</p>
              <p>Nulla mauris sem, efficitur quis orci non, faucibus ultricies massa. Phasellus lobortis sagittis blandit. Donec nisl libero, scelerisque vitae ullamcorper eget, accumsan vitae nulla. Proin eu quam ac justo pulvinar tincidunt id quis nulla. Morbi quis mauris nec tellus fermentum semper eget vel velit.</p>
              <p>Maecenas ac posuere enim. Suspendisse aliquet erat tincidunt sagittis tempus. Suspendisse sit amet venenatis ipsum, eleifend rutrum metus. Integer dignissim mollis eleifend.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="divider">
        <div className="container">
          <div className="row v-center">
            <div className="col-12 col-md-8 text-left">
              <h3>Me siga nas redes sociais </h3>  
            </div>
            <div className="col-12 col-md-4 divRedeSocial text-center">
              <a href="#" target="_Blank" className="btnRedeSocial">
                <FaInstagram size="2em" />  
              </a>
              <a href="#" target="_Blank" className="btnRedeSocial">
                <FaTwitter size="2em" /> 
              </a>
              <a href="#" target="_Blank" className="btnRedeSocial">
                <FaFacebook size="2em" /> 
              </a>
              <a href="#" target="_Blank" className="btnRedeSocial">
                <FaTelegram size="2em" /> 
              </a>
              <a href="#" target="_Blank" className="btnRedeSocial">
                <FaWhatsapp size="2em" /> 
              </a>
              <a href="#" target="_Blank" className="btnRedeSocial">
                <FaTiktok size="2em"/> 
              </a>
              <a href="#" className="btnRedeSocial" style={{marginRight: 0}}>
                <FaYoutube size="2em" /> 
              </a>
            </div>
          </div>
        </div>
      </div>
      <div id="bodyPlans">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h1>Materiais</h1>
              <span>Fique por dentro, encontre aqui os nosso materias de campanha!</span>  
              <br /><br /><br />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-4">
              <div className="itemPlan">
                <div className="text-center">
                  <FaScroll size="5em" className="iconPlan"/>
                  <p className="title">Plano de Governo</p>
                </div>
                <div className="text-center">
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis hendrerit, ex quis faucibus tempus, odio turpis rhoncus felis, ac aliquet arcu quam non dui. Mauris laoreet orci vel est tristique, et condimentum risus aliquet.</p>
                  <hr />
                  <a href="#" target="_Blank" className="btn btn-default">
                    Conferir
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="itemPlan">
                <div className="text-center">
                  <FaPhotoVideo size="5em" className="iconPlan"/>
                  <p className="title">Conteúdos</p>
                </div>
                <div className="text-center">
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis hendrerit, ex quis faucibus tempus, odio turpis rhoncus felis, ac aliquet arcu quam non dui. Mauris laoreet orci vel est tristique, et condimentum risus aliquet.</p>
                  <hr />
                  <a href="#" target="_Blank" className="btn btn-default">
                    Conferir
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="itemPlan">
                <div className="text-center">
                  <FaDownload size="5em" className="iconPlan"/>
                  <p className="title">Materias para Divulgação</p>
                </div>
                <div className="text-center">
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis hendrerit, ex quis faucibus tempus, odio turpis rhoncus felis, ac aliquet arcu quam non dui. Mauris laoreet orci vel est tristique, et condimentum risus aliquet.</p>
                  <hr />
                  <a href="#" target="_Blank" className="btn btn-default">
                    Conferir
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-4 text-center" style={{marginBottom: 15}}>
              <h1 onClick={() => window.location = './'}>Seu Nome</h1>
            </div>
            <div className="col-12 col-md-4 text-center" style={{marginBottom: 15}}>
              <h5 style={{marginBottom: 15}}>Acompanhe nas minhas redes sociais</h5>
              <a href="#" target="_Blank" className="btnRedeSocial">
                <FaInstagram size="2em" />  
              </a>
              <a href="#" target="_Blank" className="btnRedeSocial">
                <FaTwitter size="2em" /> 
              </a>
              <a href="#" target="_Blank" className="btnRedeSocial">
                <FaFacebook size="2em" /> 
              </a>
              <a href="#" target="_Blank" className="btnRedeSocial">
                <FaTelegram size="2em" /> 
              </a>
              <a href="#" target="_Blank" className="btnRedeSocial">
                <FaWhatsapp size="2em" /> 
              </a>
              <a href="#" target="_Blank" className="btnRedeSocial">
                <FaTiktok size="2em"/> 
              </a>
              <a href="#" className="btnRedeSocial" style={{marginRight: 0}}>
                <FaYoutube size="2em" /> 
              </a>
            </div>
            <div className="col-12 col-md-4 text-center">
              <img src="" alt="Logo do Partido"/>
            </div>
          </div>
        </div>
      </div>
      <div className="container" style={{paddingTop: 15}}>
        <div className="row">
          <div className="col-12 text-center">
            <p>Desenvolvido por <a href="https://tweezer.com.br" target="_Blank">Tweezer | Agência Digital</a> © 43.112.901/0001-88</p>  
          </div>
        </div>
      </div>
      <hr />
      <br /> <br />
      <div className="container" style={{paddingTop: 15}}>
        <div className="row">
          <div className="col-12 text-center">
            <h1>GOSTOU? ESSA PÁGINA PODE SER SUA!</h1>
            <p>Criamos a sua página personalizada para sua campanha eleitoral, com seu domínio personalizado e entragamos em até 72 horas!</p>
          </div>
          <div className="col-12">
            <br />
            <h4><i className="iconCheck">✔ </i> Página personalizada com seus dados e informações da sua campanha;</h4>
            <h4><i className="iconCheck">✔ </i> Links para redes sociais;</h4>
            <h4><i className="iconCheck">✔ </i> Link para doações;</h4>
            <h4><i className="iconCheck">✔ </i> Links para materias da campanha (Banners, vídeos, imagens e afins);</h4>
            <h4><i className="iconCheck">✔ </i> Personalizado com as cores do seu partido;</h4>
            <h4><i className="iconCheck">✔ </i> Layout Responsivo (Ajustável a qualquer tamanho de tela);</h4>
            <h4><i className="iconCheck">✔ </i> Domínio próprio e personalizado (Incluso);</h4>
            <h4><i className="iconCheck">✔ </i> Implementação de técnicas de SEO (Para mecanismos de busca);</h4>
            <h4><i className="iconCheck">✔ </i> Integração com o Google Analytics;</h4>
            <h4><i className="iconCheck">✔ </i> Entregamos em até 72 horas;</h4>
            <h4><i className="iconCheck">✔ </i> Hospedagem inclusa (3 meses);</h4>
          </div>
          <div className="col-12 text-center">
            <br /><br /><br />
            <div className="priceMouth">
              <div className="text-center">
                De R$ <span className="oldPrice">4.990,00</span> por:
              </div>
              <div className="text-center">
                R$<strong className="amount"><span className="big" id="valorMensal"> 3.490,00 </span></strong>
              </div>
            </div>
            <br /><br />
            <a href="https://mpago.la/2HaNvUa" className="btn btn-default" style={{paddingVertical: 15, paddingLeft: 40, paddingRight: 40,borderRadius: 5}}>
              COMPRAR AGORA
            </a>
            <br /><br /><br /><br />
          </div>
          <div className="col-12 text-center">
            <p>*Após a confirmação do pagamento será solicitado via e-mail as informações para a personalização da sua Landing Page. </p>
          </div>
        </div>
      </div>
      <br />
      <div className="container" style={{paddingTop: 15}}>
          <hr />
        <div className="row">
          <div className="col-4">
            <p>CNPJ: 43.112.901/0001-88</p>  
          </div>
          <div className="col-4 text-center">
            <p>© <a href="https://tweezer.com.br" target="_Blank">Tweezer | Agência Digital</a></p>  
          </div>
          <div className="col-4 text-right">
            <p>Horizontina - RS</p>  
          </div>
        </div>
      </div>
      <div className="divBtnWhatsApp">
        <button className="btnWhatsApp" onClick={() => window.open("https://api.whatsapp.com/send/?phone=5555984619162&text=Ol%C3%A1,%20gostaria%20de%20adquirir%20a%20landing%20page%20para%20a%20campanha%20eleitoral%20de%202022!", "_Blank")}>
          <FaWhatsapp size="2.2em" color="#fff"/>
        </button>
      </div>

      <Modal
        isOpen={modalShow}
        //onAfterOpen={afterOpenModal}
        onRequestClose={() => {setModalShow(!modalShow)}}
        style={customStylesModal}
        contentLabel="Oferta"
      >
        <button className="btnCloseModal" onClick={() => {setModalShow(!modalShow)}}>✘</button>
        <div className="form-group col-12 text-center">
          <br />
          <h4 style={{color: 'initial', marginBottom: '2rem'}}><b>Página de Exemplo!</b></h4>
          <p style={{color: 'initial'}}>Essa é apenas uma página de exemplo para você conferir como será a sua página. Ao final da página tem as instruções para que você possa adquirir a sua própria página personalizada!</p>
          <a href="#" onClick={() => {setModalShow(!modalShow)}} className="btn btn-default" style={{padding: "15px 35px", marginTop: 15}}>
            Ver Exemplo
          </a>
          <div className="text-center" style={{paddingTop: 20, marginBottom: -18}}>
            <span style={{color: 'initial', fontSize: 14}} className="text-center obs">* Entregamos a sua página em até 72h!</span>
          </div>
        </div>
      </Modal>
    </div>
  );
 
}