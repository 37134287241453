import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import Index from './pages/Index';
import Obrigado from './pages/Obrigado';

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Index} />
        <Route path="/obrigado" exact component={Obrigado} />
        <Redirect to="/" /> {/*Qualquer outra rota redireciona para a index*/}
      </Switch>
    </BrowserRouter>
  );
}