import React, { useState, useEffect, useContext } from 'react';
import './styles.css';
import '../../App.css';

function Obrigado() {
  return (
    <div className="container v-center">
      <div id="cadastro" className="col-12 col-md-8 offset-md-2">
        <div className="text-center">
          <br/> <br/> <br/> <br/>
          <div className="col-12">
            <h1 id="logo">Landing Page | Campanha Eleitoral 2022</h1>  
          </div> 
          <br/>
          <h3>Obrigado, o seu pagamento está sendo processado!</h3>
          <br/><br/>
          <p>Você receberá todas as orientações e as atualizações do seu pedido via e-mail!</p>
          <p>Qualquer dúvida entre em através do e-mail: <a href="mailto:contato@tweezer.com.br">contato@tweezer.com.br</a></p>
          <p>Ou através do nosso suporte via <a href="http://meuwhats.cf/tweezer">WhatsApp</a>!</p>
        </div> 
        <br/> <br/> <br/> <br/>
      </div>
    </div>
  );
}

export default Obrigado;
